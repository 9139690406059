<template>
    <div  class="conflex ml-3" style="margin-left:auto;align-items:center">
        <div class="blue-grey--text text--lighten-4 ml-10 mr-3">
            {{ $store.state.G.USUARIO  }}
        </div>

        <!-- Ventana -->
        <div class="text-center">
            <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-width="100"
                offset-x
                max-width="400px"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-bind="attrs"
                        color="grey lighten-2"
                        class="blue--text text--darken-4 m-2"
                        fab
                        small
                        :title="$store.state.login_estado? 'Cerrar Sesión' : 'Iniciar Sesión'"
                        v-on="$store.state.login_estado ? on : ''"
                        @click="open()"
                    >
                        <v-icon> {{ $store.state.login_estado?"mdi-account-lock":"mdi-lock" }}</v-icon>
                    </v-btn>
                </template>

                <v-card>
                    <v-list>
                        <v-list-item>
                        <v-list-item-avatar>
                            <!-- <img src="https://cdn.vuetifyjs.com/images/john.jpg" alt="Usuario"> -->

                            <v-icon large v-if="!imagen">mdi-account-circle</v-icon>
                            <v-img v-else v-bind="$attrs" :aspect-ratio="3/4" :src="imagen ? imagen : require('@/assets/img/noimg.jpg')"></v-img>

                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title class="font-weight-bold">{{USU.usu}}</v-list-item-title>
                            <v-list-item-subtitle>{{ USU.name }}</v-list-item-subtitle>
                            <v-list-item-subtitle class="font-italic">{{ USU.dep }}</v-list-item-subtitle>
                            <v-list-item-subtitle class="blue--text">{{ USU.acceso }}</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                            <!-- <v-btn
                                :class="fav ? 'red--text' : ''"
                                icon
                                @click="fav = !fav"
                                >
                                <v-icon>mdi-heart</v-icon>
                            </v-btn> -->
                            <v-btn icon large class="gray--text" @click="close()">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>

                        </v-list-item-action>
                        </v-list-item>
                    </v-list>

                    <!-- <v-divider></v-divider>

                    <v-list>
                        <v-list-item>
                        <v-list-item-action>
                            <v-switch
                            v-model="message"
                            color="purple"
                            ></v-switch>
                        </v-list-item-action>
                        <v-list-item-title>Enable messages</v-list-item-title>
                        </v-list-item>

                        <v-list-item>
                        <v-list-item-action>
                            <v-switch
                            v-model="hints"
                            color="purple"
                            ></v-switch>
                        </v-list-item-action>
                        <v-list-item-title>Enable hints</v-list-item-title>
                        </v-list-item>
                    </v-list> -->

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn small text color="primary" class="ma-3" outlined @click="logOut()">Cerrar Sesión</v-btn>
                        <!-- <v-btn text @click="menu=false">Cancel</v-btn> -->
                        <!-- <v-btn text @click="menu=false" color="primary">Save</v-btn> -->

                    </v-card-actions>
                </v-card>
            </v-menu>
        </div>

</div>
</template>

  <script>
    export default {

      data: () => ({
        name: 'AppBarInfo',
        fav: true,
        menu: false,
        message: false,
        hints: true,

        USU: {},

        /* items: [
            { text: 'My Files', icon: 'mdi-folder' },
            { text: 'Shared with me', icon: 'mdi-account-multiple' },
            { text: 'Starred', icon: 'mdi-star' },
            { text: 'Recent', icon: 'mdi-history' },
            { text: 'Offline', icon: 'mdi-check-circle' },
            { text: 'Uploads', icon: 'mdi-upload' },
            { text: 'Backups', icon: 'mdi-cloud-upload' },
        ], */
    }),

        mounted() {
            this.USU= this.$store.state.G.USUDAT;
        },

        methods: {

            open() {
                if (!this.$store.state.login_estado) { this.close(); return; }
                // leemos datos
                this.USU= this.$store.state.G.USUDAT;
            },

            // cerrar
            close() {
                if (!this.$store.state.login_estado) return;
                this.menu=!this.menu;
            },

            // cerrar session parent
            logOut () {
                this.menu=false;
                this.$emit('logOut');
            }
        },
        
        computed: {
            imagen() {
                return this.USU.img ? this.$store.state.G.url_tmpNV + this.USU.img : false;
            },

        }
    }
  </script>